import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext'; // Adjust path if needed
import './Login.css'; // Import the CSS file
import { Link } from 'react-router-dom'; // Import Link from react-router-dom for navigation
import Modal from 'react-modal'; // Import react-modal

Modal.setAppElement('#root'); // For accessibility

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const navigate = useNavigate();
    const { setAuth } = useContext(AuthContext);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/api/login', { username, password });
            const { user } = response.data;
            setAuth(user);
            if (user.roleID === 1) {
                navigate('/orgdashboard');
            } else if (user.roleID === 2) {
                navigate('/dashboard');
            } else if (user.roleID === 3) {
                navigate('/myreport');
            } else {
                navigate('/');
            } 
            setAuth(user);
        } catch (error) {
            console.log('Login error:', error);
            setError('Invalid username or password');
        }
    };

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    return (
        <div className="login-wrapper">
            <div className="login-container">
                <form onSubmit={handleSubmit}>
                    <h2>Login</h2>
                    {error && <div className="error">{error}</div>}
                    <div className="form-group">
                        <label htmlFor="username">Username</label>
                        <input
                            type="text"
                            id="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit">Login</button>
                    <div className="not-registered">
                        Not Registered? <Link to="/Register">Register</Link>
                    </div>
                    <div className="forgot-password">
                        <a href="#" onClick={(e) => { e.preventDefault(); openModal(); }}>Forgot Password?</a>
                    </div>
                </form>
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="Forgot Password"
                    className="modal"
                    overlayClassName="overlay"
                >
                    <h2>Forgot Password</h2>
                    <p>Please contact your administrator.</p>
                    <button onClick={closeModal}>Close</button>
                </Modal>
            </div>
        </div>
    );
};

export default Login;
