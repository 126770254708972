// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import Header from './components/Header';
import Footer from './components/Footer';
import Index from './'; // Import the IndexPage component
import Login from './components/Login';
import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';
import Services from './components/Services';
import PrivacyPolicy from './components/PrivacyPolicy';
import FAQ from './components/FAQ';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import OrgDashboard from './components/OrgDashboard';
import FileManagement from './components/FileManagement';
import OrgFileManagement from './components/OrgFileManagement';
import MyReport from './components/MyReport';
import HowTo from './components/HowTo';
import Admin from './components/Admin';
import OrgAdmin from './components/OrgAdmin';
import EmpProfile from './components/EmpProfile';
import UserSettings from './components/UserSettings';
import OrgAdminSettings from './components/OrgAdminSettings';
import AdminSettings from './components/AdminSettings';
import './styles.css';

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="app-container">
          <Header />
          <div className="content-container">
            <Routes>
              <Route path="/" element={<Index />} />
              <Route path="/login" element={<Login />} />
              <Route path="/aboutus" element={<AboutUs />} />
              <Route path="/contactus" element={<ContactUs />} />
              <Route path="/services" element={<Services />} />
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/register" element={<Register />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/orgdashboard" element={<OrgDashboard />} />
              <Route path="/filemanagement" element={<FileManagement />} />
              <Route path="/orgfilemanagement" element={<OrgFileManagement />} />
              <Route path="/myreport" element={<MyReport />} />
              <Route path="/empprofile" element={<EmpProfile />} />
              <Route path="/howto" element={<HowTo />} />
              <Route path="/admin" element={<Admin />} />
              <Route path="/orgadmin" element={<OrgAdmin />} />
              <Route path="/usersettings" element={<UserSettings />} />
              <Route path="/orgadminsettings" element={<OrgAdminSettings />} />
              <Route path="/adminsettings" element={<AdminSettings />} />
              {/* Add other routes here */}
            </Routes>
          </div>
          <Footer />
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
