import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import './OrgAdmin.css'; // Import the CSS file
import { AuthContext } from '../context/AuthContext';
import AssignRole from './AssignRole'; // Import the AssignRole component
import ChangePassword from './ChangePassword'; // Import the ChangePassword component
import AssignUserStatus from './AssignUserStatus'; // Import the Users component
import AssignCompanyStatus from './AssignCompanyStatus'; // Import the Users component
import AssignCompanyTestStatus from './AssignCompanyTestStatus'; // Import the Users component
import AssignTestStatus from './AssignTestStatus'; // Import the Users component
import AssignTableStatus from './AssignTableStatus'; // Import the Users component


const Admin = () => {
    const { auth } = useContext(AuthContext);
    const [userNames, setUsers] = useState([]);
    const [userRoles, setRoles] = useState([]);
    const [userStatus, setStatus] = useState([]);
    const [companyStatus, setCompany] = useState([]);
    const [testStatus, setTest] = useState([]);
    const [tableStatus, setTable] = useState([]);
    const [menuItems, setMenuItems] = useState([]);
    const [selectedUser, setSelectedUser] = useState('');
    const [activeMenu, setActiveMenu] = useState('assignRole'); // State to track menu selection
    const [message, setMessage] = useState('');

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const userresponse = await axios.get('/api/admin/users', {
                    params: { name: auth.name },
                });
                setUsers(userresponse.data);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        const fetchRoles = async () => {
            try {
                const roleresponse = await axios.get('/api/admin/roles', {
                    params: { username: auth.name }, // Pass the logged-in user's name
                });
                setRoles(roleresponse.data);
            } catch (error) {
                console.error('Error fetching roles:', error);
            }
        };

        const fetchStatus = async () => {
            try {
                const statusresponse = await axios.get('/api/admin/status', {
                    params: { username: auth.name }, // Pass the logged-in user's name
                });
                setStatus(statusresponse.data);
            } catch (error) {
                console.error('Error fetching status:', error);
            }
        };

        const fetchCompany = async () => {
            try {
                const companyresponse = await axios.get('/api/admin/company', {
                    params: { username: auth.name }, // Pass the logged-in user's name
                });
                setCompany(companyresponse.data);
            } catch (error) {
                console.error('Error fetching status:', error);
            }
        };

        const fetchTest = async () => {
            try {
                const testresponse = await axios.get('/api/admin/tests', {
                    params: { username: auth.name }, // Pass the logged-in user's name
                });
                setTest(testresponse.data);
            } catch (error) {
                console.error('Error fetching status:', error);
            }
        };

        const fetchTable = async () => {
            try {
                const tableresponse = await axios.get('/api/admin/tables', {
                    params: { username: auth.name }, // Pass the logged-in user's name
                });
                setTable(tableresponse.data);
            } catch (error) {
                console.error('Error fetching status:', error);
            }
        };

        const fetchMenuItems = async () => {
            try {
                const menuresponse = await axios.get('/api/admin/menu-items');
                setMenuItems(menuresponse.data); // Directly set the data
                // Set default selected menu
                if (menuresponse.data.length > 0) {
                    setActiveMenu(menuresponse.data[0].Name); // Set default active menu
                }
            } catch (error) {
                console.error('Error fetching menu items:', error);
            }
        };
        
        fetchUsers();
        fetchRoles();
        fetchMenuItems();
        fetchCompany();
        fetchTest(); // Call fetchTest to get test statuses
        fetchTable();
    }, [auth.name]);

    const handleSuccess = () => {
        setMessage('');
    };

    return (
        <div className="page-container">
             <div className="left-side">
                  {/* Left Menu */}
            <h2>Tables</h2>
            <ul>
                {menuItems.length > 0 ? (
                    menuItems.map((item, index) => (
                        <li
                            key={index}
                            onClick={() => setActiveMenu(item.Name)}
                            className={activeMenu === item.Name ? 'active' : ''}
                        >
                            {item.Name}
                        </li>
                    ))
                ) : (
                    <li>No menu items available</li>
                )}
            </ul>
        </div>


            <div className="right-side">
                <div className="content">
                    {activeMenu === 'Roles' && (
                        <AssignRole 
                            userNames={userNames} 
                            userRoles={userRoles} 
                            onSuccess={handleSuccess} 
                        />
                    )}
                    {activeMenu === 'Change Password' && (
                        <ChangePassword 
                            userNames={userNames}
                            selectedUser={selectedUser} 
                        />
                    )}

                        {activeMenu === 'Users' && (
                        <AssignUserStatus 
                            userNames={userNames} 
                            userStatus={userStatus} 
                            onSuccess={handleSuccess} 
                        />
                    )}

                        {activeMenu === 'Company Names' && (
                        <AssignCompanyStatus 
                            userNames={userNames} 
                            userStatus={companyStatus} 
                            onSuccess={handleSuccess} 
                        />
                    )}
                        {activeMenu === 'Company Tests' && (
                        <AssignCompanyTestStatus 
                        userNames={userNames} 
                        companyStatus={companyStatus} 
                        testStatus={testStatus} // Pass the test statuses
                        onSuccess={handleSuccess} 
                        />
                    )}
                     {activeMenu === 'Test Names' && (
                        <AssignTestStatus 
                            userNames={userNames} 
                            testStatus={testStatus} 
                            onSuccess={handleSuccess} 
                        />
                    )}
                      {activeMenu === 'Table Status' && (
                        <AssignTableStatus 
                        tableList={tableStatus} // It should be tableList, not tableStatus
                        onSuccess={handleSuccess} 
                    />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Admin;
