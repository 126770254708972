import React, { useEffect, useState, useRef, useContext } from 'react';
import axios from 'axios';
import Plot from 'react-plotly.js';
import { Bar, Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { AuthContext } from '../context/AuthContext';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement,
} from 'chart.js';

import './Dashboard.css'; // Import your CSS file

// Register Chart.js components
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement,
  ChartDataLabels
);

const Dashboard = () => {
  const { auth } = useContext(AuthContext);

  // State for menu items
  const [leftMenuItems, setLeftMenuItems] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState(null);

  // State for chart data
  const [chartData, setChartData] = useState(null);
  const [barChartData, setBarChartData] = useState(null);
  const [pieChartData, setPieChartData] = useState(null);
  const [tableData, setTableData] = useState(null);

  // Loading and error states
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Active tab state
  const [activeTab, setActiveTab] = useState('BarChart');

  // Dropdown options states
  const [departments, setDepartments] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [contractors, setContractors] = useState([]);
  const [annualYears, setAnnualYears] = useState([]);
  const [empTypes, setEmpTypes] = useState([]);
  const [genders, setGenders] = useState([]);
  const [units, setUnits] = useState([]);

  // Selected values for dropdowns
  const [selectedCompanyName, setSelectedCompanyName] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [selectedBlock, setSelectedBlock] = useState('');
  const [selectedContractor, setSelectedContractor] = useState('');
  const [selectedAnnualYear, setSelectedAnnualYear] = useState('');
  const [selectedEmpType, setSelectedEmpType] = useState('');
  const [selectedGender, setSelectedGender] = useState('');
  const [selectedUnit, setSelectedUnit] = useState('');

  // Refs for charts
  const barChartRef = useRef(null);
  const pieChartRef = useRef(null);

  // Fetch initial data for menu items and dropdown options
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setLoading(true);

        // Fetch departments
        const companynameResponse = await axios.get('http://localhost:5000/api/SUcompanynames', {
          params: { name: auth.name },
        });
        const companyNamesData = companynameResponse.data;

        
        if (companyNamesData.length === 1) {
        const defaultCompanyName = companyNamesData[0]; // Assuming the response returns an array of company objects or strings
        setSelectedCompanyName(defaultCompanyName);

        // Fetch left menu items filtered by the selected company
        const menuResponse = await axios.get('http://localhost:5000/api/dashboard', {
          params: { name: auth.name, companynames: defaultCompanyName },
        });
        setLeftMenuItems(menuResponse.data.leftMenuItems);

        // Set default selected menu if available
        if (menuResponse.data.leftMenuItems.length > 0) {
          setSelectedMenu(menuResponse.data.leftMenuItems[0].LeftMenu);
        }
      }
        // Fetch departments
        const departmentResponse = await axios.get('http://localhost:5000/api/departments', {
          params: { name: auth.name },
        });
        setDepartments(departmentResponse.data);

        // Fetch all blocks
        const blockResponse = await axios.get('http://localhost:5000/api/blocks', {
          params: { name: auth.name },
        });
        setBlocks(blockResponse.data);

        // Fetch all contractors
        const contractorResponse = await axios.get('http://localhost:5000/api/contractors');
        setContractors(contractorResponse.data);

        // Fetch annual years
        const annualYearResponse = await axios.get('http://localhost:5000/api/annual-years');
        setAnnualYears(annualYearResponse.data);

        // Fetch employee types
        const empTypeResponse = await axios.get('http://localhost:5000/api/employee-types');
        setEmpTypes(empTypeResponse.data);

        // Fetch genders
        const genderResponse = await axios.get('http://localhost:5000/api/genders');
        setGenders(genderResponse.data);

        // Fetch units
        const unitResponse = await axios.get('http://localhost:5000/api/units');
        setUnits(unitResponse.data);

        setLoading(false);
      } catch (err) {
        console.error('Error fetching initial data:', err);
        setError(err);
        setLoading(false);
      }
    };

    fetchInitialData();
  }, [auth.name]);

  // Fetch blocks when selectedDepartment changes
  useEffect(() => {
    const fetchBlocks = async () => {
      try {
        // Construct params object based on selected filters
        const params = {
          name: auth.name, // Include name if available
        };
  
        // Add department to params only if it's selected
        if (selectedDepartment) {
          params.department = selectedDepartment;
        }

        // Fetch blocks based on the available parameters
        const response = await axios.get('http://localhost:5000/api/contractors', {
          params, // Send the constructed params object
        });
  
        // Set blocks state with the fetched data
        setBlocks(response.data);
  
        // Reset selected block whenever filters change
        setSelectedBlock('');
      } catch (error) {
        console.error('Error fetching blocks:', error);
      }
    };
  
    // Call fetchBlocks whenever dependencies change
    fetchBlocks();
  }, [selectedDepartment, auth.name]);

  // Fetch contractors when selectedDepartment or selectedBlock changes
  useEffect(() => {
    const fetchContractors = async () => {
      try {
        // Construct params object based on selected filters
        const params = {
          name: auth.name, // Include name if available
        };
  
        // Add department to params only if it's selected
        if (selectedDepartment) {
          params.department = selectedDepartment;
        }
  
        // Add block to params only if it's selected
        if (selectedBlock) {
          params.block = selectedBlock;
        }
  
        // Fetch contractors based on the available parameters
        const response = await axios.get('http://localhost:5000/api/contractors', {
          params, // Send the constructed params object
        });
  
        // Set contractors state with the fetched data
        setContractors(response.data);
  
        // Reset selected contractor whenever filters change
        setSelectedContractor('');
      } catch (error) {
        console.error('Error fetching contractors:', error);
      }
    };
  
    // Call fetchContractors whenever dependencies change
    fetchContractors();
  }, [selectedDepartment, selectedBlock, auth.name]);
  

  // Fetch annualyear when selectedDepartment or selectedBlock or selectContractor changes
  useEffect(() => {
    const fetchAnnualYears = async () => {
      try {
        // Construct params object based on selected filters
        const params = {
          name: auth.name || '', // Include name if available
        };
  
        // Add department to params only if it's selected
        if (selectedDepartment) {
          params.department = selectedDepartment;
        }
  
        // Add block to params only if it's selected
        if (selectedBlock) {
          params.block = selectedBlock;
        }
  
        // Add annualyears to params only if it's selected
        if (selectedAnnualYear) {
          params.annualyears = selectedAnnualYear;
        }
  
        // Fetch annual years based on the available parameters
        const response = await axios.get('http://localhost:5000/api/annual-years', {
          params, // Send the constructed params object
        });
  
        // Set annual years state with the fetched data
        setAnnualYears(response.data);
  
        // Reset selected annual year whenever filters change
        setSelectedAnnualYear('');
      } catch (error) {
        console.error('Error fetching annual years:', error);
      }
    };
  
    // Call fetchAnnualYears whenever dependencies change
    fetchAnnualYears();
  }, [selectedDepartment, selectedBlock, selectedAnnualYear, auth.name]);
  

  // Fetch chart and table data whenever any filter changes
  useEffect(() => {
    const fetchChartData = async () => {
      try {
        if (!selectedMenu) return;

        const response = await axios.get('http://localhost:5000/api/data', {
          params: {
            leftMenu: selectedMenu,
            CompanyName: selectedCompanyName,
            Department: selectedDepartment,
            Block: selectedBlock,
            Contractor: selectedContractor,
            AnnualYear: selectedAnnualYear,
            EmpType: selectedEmpType,
            Gender: selectedGender,
            Unit: selectedUnit,
          },
        });

        const data = response.data;

        if (!data || !Array.isArray(data) || data.length === 0) {
          setChartData(null);
          setBarChartData(null);
          setPieChartData(null);
          setTableData(null);
          return;
        }

        // Prepare data for 3D Chart
        const ageGroups = Array.from(new Set(data.map((item) => item.AgeChart))).filter(Boolean);
        const columnData = Array.from(
          new Set(data.map((item) => item[selectedMenu]))
        ).filter(Boolean);

        if (ageGroups.length === 0 || columnData.length === 0) {
          setChartData(null);
          setBarChartData(null);
          setPieChartData(null);
          setTableData(null);
          return;
        }

        const z = columnData.map((column) => {
          return ageGroups.map((ageGroup) => {
            const item = data.find(
              (dataItem) =>
                dataItem.AgeChart === ageGroup && dataItem[selectedMenu] === column
            );
            return item ? item.EmployeeCount : 0;
          });
        });

        setChartData({
          z,
          x: ageGroups,
          y: columnData,
          colorscale: 'Viridis',
          showscale: true,
        });

        // Prepare data for Bar Chart
        const barDatasets = columnData.map((column) => ({
          label: column,
          data: ageGroups.map((ageGroup) => {
            const item = data.find(
              (dataItem) =>
                dataItem.AgeChart === ageGroup && dataItem[selectedMenu] === column
            );
            return item ? item.EmployeeCount : 0;
          }),
          backgroundColor: getRandomBrightColor(),
          borderColor: getRandomBrightColor(),
          borderWidth: 1,
        }));

        setBarChartData({
          labels: ageGroups,
          datasets: barDatasets,
        });

        // Prepare data for Pie Chart
        const pieLabels = columnData;
        const pieData = columnData.map((column) => {
          const total = data
            .filter((item) => item[selectedMenu] === column)
            .reduce((sum, item) => sum + item.EmployeeCount, 0);
          return total;
        });

        setPieChartData({
          labels: pieLabels,
          datasets: [
            {
              data: pieData,
              backgroundColor: pieLabels.map(() => getRandomBrightColor()),
            },
          ],
        });

        // Set table data
        setTableData(data);
      } catch (err) {
        console.error('Error fetching chart data:', err);
        setChartData(null);
        setBarChartData(null);
        setPieChartData(null);
        setTableData(null);
      }
    };

    fetchChartData();
  }, [
    selectedMenu,
    selectedCompanyName,
    selectedDepartment,
    selectedBlock,
    selectedContractor,
    selectedAnnualYear,
    selectedEmpType,
    selectedGender,
    selectedUnit,
  ]);

  // Event handlers for dropdown changes
  const handleMenuClick = (menu) => {
    setSelectedMenu(menu);
    setActiveTab('BarChart');
  };


  const handleDepartmentChange = (event) => {
    setSelectedDepartment(event.target.value);
  };

  const handleBlockChange = (event) => {
    setSelectedBlock(event.target.value);
  };

  const handleContractorChange = (event) => {
    setSelectedContractor(event.target.value);
  };

  const handleAnnualYearChange = (event) => {
    setSelectedAnnualYear(event.target.value);
  };

  const handleEmpTypeChange = (event) => {
    setSelectedEmpType(event.target.value);
  };

  const handleGenderChange = (event) => {
    setSelectedGender(event.target.value);
  };

  const handleUnitChange = (event) => {
    setSelectedUnit(event.target.value);
  };

  // Function to generate random bright colors
  const getRandomBrightColor = () => {
    const r = Math.floor(Math.random() * 156) + 100;
    const g = Math.floor(Math.random() * 156) + 100;
    const b = Math.floor(Math.random() * 156) + 100;
    return `rgba(${r}, ${g}, ${b}, 0.8)`;
  };

  // Function to download chart as an image
  const downloadChartAsImage = (chartRef, chartType) => {
    if (chartRef && chartRef.current && chartRef.current.canvas) {
      const link = document.createElement('a');
      link.href = chartRef.current.canvas.toDataURL('image/png');
      link.download = `${chartType}-chart.png`;
      link.click();
    } else {
      console.error(`${chartType} chart reference is not available.`);
    }
  };

  // Function to convert table data to CSV
  const convertToCSV = (tableData) => {
    if (!tableData || tableData.length === 0) {
      return '';
    }

    const headers = Object.keys(tableData[0]);
    const rows = tableData.map((row) =>
      headers.map((header) => `"${row[header] || ''}"`).join(',')
    );

    return [headers.join(','), ...rows].join('\n');
  };

  // Function to download table data as CSV
  const downloadCSV = () => {
    const csvData = convertToCSV(tableData);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);

    link.setAttribute('href', url);
    link.setAttribute('download', `${selectedMenu || 'table_data'}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">Error: {error.message}</div>;
  }

  return (
    <div className="dashboard">
      {/* Left Menu */}
      <div className="left">
        <h2>Tests</h2>
        <ul>
          {leftMenuItems.length > 0 ? (
            leftMenuItems.map((item, index) => (
              <li key={index}>
                <button
                  className={`menu-item ${
                    selectedMenu === item.LeftMenu ? 'active' : ''
                  }`}
                  onClick={() => handleMenuClick(item.LeftMenu)}
                >
                  {item.LeftMenu}
                </button>
              </li>
            ))
          ) : (
            <li>No menu items available</li>
          )}
        </ul>
      </div>

      {/* Middle Content */}
      <div className="middle">
        <h1>{selectedMenu ? `Chart for ${selectedMenu}` : 'Dashboard'}</h1>

        {/* Tabs */}
        <div className="tabs">
         
          <button
            className={`tab ${activeTab === 'BarChart' ? 'active' : ''}`}
            onClick={() => setActiveTab('BarChart')}
          >
            Bar Chart
          </button>
          <button
            className={`tab ${activeTab === 'PieChart' ? 'active' : ''}`}
            onClick={() => setActiveTab('PieChart')}
          >
            Pie Chart
          </button>
          <button
            className={`tab ${activeTab === 'TableData' ? 'active' : ''}`}
            onClick={() => setActiveTab('TableData')}
          >
            Table Data
          </button>
          <button
            className={`tab ${activeTab === '3DChart' ? 'active' : ''}`}
            onClick={() => setActiveTab('3DChart')}
          >
            3D Chart
          </button>
        </div>

        {/* Tab Content */}
        <div className="tab-content">
          {/* 3D Chart */}
          {activeTab === '3DChart' && chartData ? (
            <Plot
              data={[
                {
                  type: 'surface',
                  z: chartData.z,
                  x: chartData.x,
                  y: chartData.y,
                  colorscale: chartData.colorscale,
                  showscale: chartData.showscale,
                },
              ]}
              layout={{
                scene: {
                  xaxis: { title: 'Age Groups' },
                  yaxis: { title: selectedMenu },
                  zaxis: { title: 'Employee Count' },
                },
                autosize: true,
                margin: {
                  l: 50,
                  r: 50,
                  b: 50,
                  t: 50,
                },
              }}
              config={{
                displayModeBar: true,
                scrollZoom: true,
                displaylogo: false,
              }}
              style={{ width: '100%', height: '500px' }}
            />
          ) : activeTab === 'BarChart' && barChartData ? (
            <div className="chart-container">
              <Bar
                ref={barChartRef}
                data={barChartData}
                options={{
                  responsive: true,
                  plugins: {
                    legend: { position: 'top' },
                    tooltip: {
                      callbacks: {
                        label: function (context) {
                          const label = context.dataset.label || '';
                          return label ? `${label}: ${context.raw}` : '';
                        },
                      },
                    },
                    datalabels: {
                      color: 'white',
                      weight: 'bold',
                      display: true,
                      formatter: (value) => value,
                    },
                  },
                  scales: {
                    x: {
                      title: {
                        display: true,
                        text: 'Age Groups',
                      },
                    },
                    y: {
                      title: {
                        display: true,
                        text: 'Employee Count',
                      },
                      beginAtZero: true,
                    },
                  },
                  maintainAspectRatio: false,
                }}
                height={500}
              />
              <button
                onClick={() => downloadChartAsImage(barChartRef, 'Bar')}
                className="download-button"
              >
                Download Bar Chart
              </button>
            </div>
          ) : activeTab === 'PieChart' && pieChartData ? (
            <div className="chart-container">
              <Pie
                ref={pieChartRef}
                data={pieChartData}
                options={{
                  responsive: true,
                  plugins: {
                    legend: { position: 'top' },
                    tooltip: {
                      callbacks: {
                        label: function (context) {
                          const label = context.label || '';
                          const value = context.raw || 0;
                          return `${label}: ${value}`;
                        },
                      },
                    },
                    datalabels: {
                      color: 'white',
                      weight: 'bold',
                      display: true,
                      formatter: (value) => value,
                    },
                  },
                  maintainAspectRatio: false,
                }}
                height={500}
              />
              <button
                onClick={() => downloadChartAsImage(pieChartRef, 'Pie')}
                className="download-button"
                style={{ marginTop: '10px' }}
              >
                Download Pie Chart
              </button>
            </div>
          ) : activeTab === 'TableData' && tableData ? (
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    {tableData.length > 0 &&
                      Object.keys(tableData[0]).map((key, index) => (
                        <th key={index}>{key}</th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((row, index) => (
                    <tr key={index}>
                      {Object.values(row).map((value, idx) => (
                        <td key={idx}>{value}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
              <button onClick={downloadCSV} className="download-button">
                Download Table Data
              </button>
            </div>
          ) : (
            <p>No Data Available</p>
          )}
        </div>
      </div>

      {/* Right Filters */}
      <div className="right">
        <h2>Filters</h2>
        <div className="dropdown-container">
           {/* CompanyName Dropdown */}
           <div>
            <label>Company Name:&nbsp;</label>
              <span className="company-name">{selectedCompanyName || "No company name available"}</span>
          </div>
          &nbsp;&nbsp;&nbsp;
          
          {/* Department Dropdown */}
          <div className="dropdown">
            <label htmlFor="department">Department:</label>
            <select
              id="department"
              value={selectedDepartment}
              onChange={handleDepartmentChange}
            >
              <option value="">All Departments</option>
              {departments.length > 0 ? (
                departments.map((dept, index) => (
                  <option key={index} value={dept.Department || dept}>
                    {dept.Department || dept}
                  </option>
                ))
              ) : (
                <option>No departments available</option>
              )}
            </select>
          </div>

          {/* Block Dropdown */}
          <div className="dropdown">
            <label htmlFor="block">Block:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
            <select
              id="block"
              value={selectedBlock}
              onChange={handleBlockChange}
             /* disabled={!selectedDepartment}*/ 
           >
              <option value="">All Blocks</option>
              {blocks.length > 0 ? (
                blocks.map((block, index) => (
                  <option key={index} value={block.Block || block}>
                    {block.Block || block}
                  </option>
                ))
              ) : (
                <option>No blocks available</option>
              )}
            </select>
          </div>

          {/* Contractor Dropdown */}
          <div className="dropdown">
            <label htmlFor="contractor">Contractor:&nbsp;&nbsp;</label>
            <select
              id="contractor"
              value={selectedContractor}
              onChange={handleContractorChange}
              /*disabled={!selectedDepartment || !selectedBlock} // Updated condition*/
            >
              <option value="">All Contractors</option>
              {contractors.length > 0 ? (
                contractors.map((contractor, index) => (
                  <option key={index} value={contractor.Contractor || contractor}>
                    {contractor.Contractor || contractor}
                  </option>
                ))
              ) : (
                <option>No contractors available</option>
              )}
            </select>
          </div>

          {/* Annual Year Dropdown */}
          <div className="dropdown">
            <label htmlFor="annualYear">Year:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
            <select
              id="annualYear"
              value={selectedAnnualYear}
              onChange={handleAnnualYearChange}
              /*disabled={!selectedDepartment || !selectedBlock || !selectedContractor} // Updated condition*/
            >
              <option value="">All Years</option>
              {annualYears.length > 0 ? (
                annualYears.map((year, index) => (
                  <option key={index} value={year.Year || year}>
                    {year.Year || year}
                  </option>
                ))
              ) : (
                <option>No annual years available</option>
              )}
            </select>
          </div>

          {/* Employee Type Dropdown */}
          <div className="dropdown">
            <label htmlFor="empType">Emp Type:&nbsp;&nbsp;&nbsp;</label>
            <select
              id="empType"
              value={selectedEmpType}
              onChange={handleEmpTypeChange}
             /* disabled={!selectedDepartment || !selectedBlock || !selectedContractor || !selectedAnnualYear} // Updated condition*/
            >
              <option value="">All Employee Types</option>
              {empTypes.length > 0 ? (
                empTypes.map((type, index) => (
                  <option key={index} value={type}>
                    {type}
                  </option>
                ))
              ) : (
                <option>No employee types available</option>
              )}
            </select>
          </div>

          {/* Gender Dropdown */}
          <div className="dropdown">
            <label htmlFor="gender">Gender:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
            <select
              id="gender"
              value={selectedGender}
              onChange={handleGenderChange}
              /*disabled={!selectedDepartment || !selectedBlock || !selectedContractor || !selectedAnnualYear || !selectedEmpType} // Updated condition*/
            >
              <option value="">All Genders</option>
              {genders.length > 0 ? (
                genders.map((gender, index) => (
                  <option key={index} value={gender}>
                    {gender}
                  </option>
                ))
              ) : (
                <option>No genders available</option>
              )}
            </select>
          </div>

          {/* Unit Dropdown */}
          <div className="dropdown">
            <label htmlFor="unit">Unit:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
            <select id="unit" value={selectedUnit} onChange={handleUnitChange}
           /*disabled={!selectedDepartment || !selectedBlock || !selectedContractor || !selectedAnnualYear || !selectedEmpType || !selectedGender} // Updated condition*/
           >

              <option value="">All Units</option>
              {units.length > 0 ? (
                units.map((unit, index) => (
                  <option key={index} value={unit}>
                    {unit}
                  </option>
                ))
              ) : (
                <option>No units available</option>
              )}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;