import './ContactUs.css';


const HomePage = () => {
  return (
      <div className="home-page">
          <h1>Welcome Services!</h1>
          <p>This is the Services page of your application.</p>
          {/* Add more content or styling as needed */}
      </div>
  );
};

export default HomePage;