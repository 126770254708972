import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';

const AssignTableStatus = ({ onSuccess }) => {

    const [tableList, setTableList] = useState([]);
    const [statusList, setStatusList] = useState([]); // New state for statuses

    const [selectedTable, setSelectedTable] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {


                // Fetch the list of Tables
                const fetchTableList = async () => {
                    try {
                        const response = await axios.get('/api/tables');
                        console.log('Table List Response:', response.data); // Log the response
                        setTableList(response.data);
                    } catch (error) {
                        console.error('Error fetching Table list:', error);
                    }
                };
                

        // Fetch the list of statuses from the backend
        const fetchStatusList = async () => {
            try {
                const response = await axios.get('/api/status');
                setStatusList(response.data); // Assuming response contains the list of statuses
            } catch (error) {
                console.error('Error fetching status list:', error);
            }
        };

 
        fetchTableList();
        fetchStatusList(); // Fetch statuses when the component mounts
    }, []);

    const handleTableChange = (event) => {
        setSelectedTable(event.target.value);
    };
    const handleStatusChange = (event) => {
        setSelectedStatus(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        try {
            await axios.post('/api/admin/assign-Tablestatus', {
              
                tableName: selectedTable, // Include tableName here
                status: selectedStatus
            });
    
            setMessage(`The table ${selectedTable} has been assigned the status ${selectedStatus} .`);
            onSuccess();
        } catch (error) {
            console.error('Error assigning table status:', error);
            setMessage('Error assigning table status');
        }
    };
    
    return (
        <div>
        
        {/* Note above the form */}
        <p>Please select a table and status to assign. Make sure the table is active before assigning a status.</p>
        <form className="admin-form" onSubmit={handleSubmit}>
        <h2>Assign Company Table Status</h2>
            <div className="form-group">
                <label htmlFor="tableDropdown">Select table:</label>
                <select id="tableDropdown" value={selectedTable} onChange={handleTableChange}>
                    <option value="">Select a Table</option>
                    {tableList.map((table, index) => (
                        <option key={index} value={table.Name}>
                            {table.Name}
                        </option>
                    ))}
                </select>
            </div>

            <div className="form-group">
                <label htmlFor="statusDropdown">Select Status:</label>
                <select id="statusDropdown" value={selectedStatus} onChange={handleStatusChange}>
                    <option value="">Select a Status</option>
                    {statusList.map((status, index) => (
                        <option key={index} value={status.StatusID}>
                            {status.StatusName}
                        </option>
                    ))}
                </select>
            </div>

            <button type="submit">Assign Status</button>
            {message && <p className="message">{message}</p>}
        </form>
    </div>
);
};

export default AssignTableStatus;
