import React, { useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const ChangePassword = ({ onSuccess,userNames }) => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
    const [message, setMessage] = useState('');
    const [selectedUser, setSelectedUser] = useState('');

    const handleUserChange = (event) => {
        setSelectedUser(event.target.value);
    };

    const handlePasswordChange = async (event) => {
        event.preventDefault();

        if (newPassword !== confirmNewPassword) {
            setMessage('Passwords do not match.');
            return;
        }

        try {
            await axios.post('/api/user/adchange-password', {
                username: selectedUser,
                newPassword,
            });
            setMessage(`Password for user ${selectedUser} has been changed successfully.`);
            onSuccess();
        } catch (error) {
            console.error('Error changing password:', error);
            setMessage('Error changing password');
        }
    };

    return (
        <form className="admin-form" onSubmit={handlePasswordChange}>
            <h2>Change User Password</h2>
            <div className="form-group">
            <div className="form-group">
                <label htmlFor="userDropdown">Select User:</label>
                <select id="userDropdown" value={selectedUser} onChange={handleUserChange}>
                    <option value="">Select a user</option>
                    {userNames.map((userName) => (
                        <option key={userName} value={userName}>
                            {userName}
                        </option>
                    ))}
                </select>
            </div>
                <label htmlFor="newPassword">New Password for {selectedUser}:</label>
                <input
                    type={showNewPassword ? 'text' : 'password'}
                    id="newPassword"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                />
                <FontAwesomeIcon
                    icon={showNewPassword ? faEyeSlash : faEye}
                    className="password-icon"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                />
            </div>

            <div className="form-group">
                <label htmlFor="confirmNewPassword">Confirm New Password:</label>
                <input
                    type={showConfirmNewPassword ? 'text' : 'password'}
                    id="confirmNewPassword"
                    value={confirmNewPassword}
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                    required
                />
                <FontAwesomeIcon
                    icon={showConfirmNewPassword ? faEyeSlash : faEye}
                    className="password-icon"
                    onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}
                />
            </div>

            <button type="submit">Change Password</button>
            {message && <p className="message">{message}</p>}
        </form>
    );
};

export default ChangePassword;
