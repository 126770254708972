import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import './EmpProfile.css';

const MyReport = () => {
    const { auth } = useContext(AuthContext);
    const [reportData, setReportData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchReport = async () => {
            if (!auth || !auth.name) {
                setError('User not authenticated');
                return;
            }

            try {
                const response = await axios.get('/api/report', {
                    params: { username: auth.name }
                });
                
                console.log('API response:', response.data); // Debug the response data

                setReportData(response.data);
            } catch (err) {
                console.error('Error fetching report:', err);
                setError('Failed to fetch report data');
            }
        };

        fetchReport();
    }, [auth]);

    return (
        <div className="my-report-container">
            <h1>Welcome  {auth?.name || 'Guest'}!</h1>
            {error && <div className="error">{error}</div>}
            {reportData ? (
                <div className="report-grid">
                    <div className="report-item">
                        <strong>Salutation:</strong> {reportData.Salutation}
                    </div>
                    <div className="report-item">
                        <strong>Company Name:</strong> {reportData['CompanyName']}
                    </div>
                    <div className="report-item">
                        <strong>Annual Year:</strong> {reportData['AnnualYear']}
                    </div>
                    <div className="report-item">
                        <strong>Company Address:</strong> {reportData['CompanyAddress']}
                    </div>
                    <div className="report-item">
                        <strong>ShortDate:</strong> {reportData.ShortDate}
                    </div>
                    <div className="report-item">
                        <strong>Smp ID:</strong> {reportData['SmpID']}
                    </div>
                    <div className="report-item">
                        <strong>Employee ID:</strong> {reportData['EmpID']}
                    </div>
                    <div className="report-item">
                        <strong>Employee Type:</strong> {reportData['EmpType']}
                    </div>
                    <div className="report-item">
                        <strong>Unit:</strong> {reportData.Unit}
                    </div>
                    <div className="report-item">
                        <strong>Bar Code:</strong> {reportData['BarCode']}
                    </div>
                    <div className="report-item">
                        <strong>Block:</strong> {reportData.Block}
                    </div>
                    <div className="report-item">
                        <strong>Department:</strong> {reportData.Department}
                    </div>
                    <div className="report-item">
                        <strong>Contractor:</strong> {reportData.Contractor}
                    </div>
                    <div className="report-item">
                        <strong>Age:</strong> {reportData.Age}
                    </div>
                    <div className="report-item">
                        <strong>Sex:</strong> {reportData.Sex}
                    </div>
                    <div className="report-item">
                        <strong>Report State:</strong> {reportData['ReportState']}
                    </div>
                    {/* Add more items as needed */}
                </div>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
};

export default MyReport;
