import React, { useState, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';

const AssignRole = ({ userNames, userRoles, onSuccess }) => {
    const { auth } = useContext(AuthContext);
    const [selectedUser, setSelectedUser] = useState('');
    const [selectedRole, setSelectedRole] = useState('');
    const [message, setMessage] = useState('');

    const handleUserChange = (event) => {
        setSelectedUser(event.target.value);
    };

    const handleRoleChange = (event) => {
        setSelectedRole(event.target.value);
    };

    const handleSubmitRoleChange = async (event) => {
        event.preventDefault();
        try {
            await axios.post('/api/admin/assign-role', {
                username: selectedUser,
                roleName: selectedRole,
            });
            setMessage(`The user ${selectedUser} has been assigned the role ${selectedRole}.`);
            onSuccess();
        } catch (error) {
            console.error('Error assigning role:', error);
            setMessage('Error assigning role');
        }
    };

    return (
        <form className="admin-form" onSubmit={handleSubmitRoleChange}>
            <h2>Assign User Role</h2>
            <div className="form-group">
                <label htmlFor="userDropdown">Select User:</label>
                <select id="userDropdown" value={selectedUser} onChange={handleUserChange}>
                    <option value="">Select a user</option>
                    {userNames.map((userName) => (
                        <option key={userName} value={userName}>
                            {userName}
                        </option>
                    ))}
                </select>
            </div>

            <div className="form-group">
                <label htmlFor="roleDropdown">Select Role:</label>
                <select id="roleDropdown" value={selectedRole} onChange={handleRoleChange}>
                    <option value="">Select a role</option>
                    {userRoles.map((userRole) => (
                        <option key={userRole.RoleID} value={userRole.RoleName}>
                            {userRole.RoleName}
                        </option>
                    ))}
                </select>
            </div>

            <button type="submit">Assign Role</button>
            {message && <p className="message">{message}</p>}
        </form>
    );
};

export default AssignRole;
