import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';

const AssignUserStatus = ({ userNames, onSuccess }) => {
    const { auth } = useContext(AuthContext);
    const [selectedUser, setSelectedUser] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [statusList, setStatusList] = useState([]);
    const [message, setMessage] = useState('');

    useEffect(() => {
        // Fetch the list of statuses
        const fetchStatusList = async () => {
            try {
                const response = await axios.get('/api/status');
                setStatusList(response.data); // Assuming the data is the status array
            } catch (error) {
                console.error('Error fetching status list:', error);
            }
        };

        fetchStatusList();
    }, []);

    const handleUserChange = (event) => {
        setSelectedUser(event.target.value);
    };

    const handleStatusChange = (event) => {
        setSelectedStatus(event.target.value);
    };

    const handleSubmitStatusChange = async (event) => {
        event.preventDefault();
        
        // Log the selected values before making the request
        console.log('Selected User:', selectedUser);
        console.log('Selected Status:', selectedStatus);
    
        // Check if both user and status are selected before proceeding
        if (!selectedUser || !selectedStatus) {
            setMessage('Please select both a user and a status.');
            return;
        }
        
        try {
            await axios.post('/api/admin/assign-userstatus', {
                username: selectedUser,
                Status: selectedStatus, // This should send "Active" or "Inactive"
            });
            setMessage(`The user ${selectedUser} has been assigned the Status ${selectedStatus}.`);
            onSuccess();
        } catch (error) {
            console.error('Error assigning Status:', error.response?.data || error.message);
            setMessage('Error assigning Status');
        }
    };
    
    

    return (
        <form className="admin-form" onSubmit={handleSubmitStatusChange}>
            <h2>Assign User Status</h2>
            <div className="form-group">
                <label htmlFor="userDropdown">Select User:</label>
                <select id="userDropdown" value={selectedUser} onChange={handleUserChange}>
                    <option value="">Select a user</option>
                    {userNames.map((userName) => (
                        <option key={userName} value={userName}>
                            {userName}
                        </option>
                    ))}
                </select>
            </div>

            <div className="form-group">
                <label htmlFor="StatusDropdown">Select Status:</label>
                <select id="StatusDropdown" value={selectedStatus} onChange={handleStatusChange}>
                    <option value="">Select a Status</option>
                    {statusList.map((status, index) => (
                        <option key={index} value={status.StatusName}>
                            {status.StatusName}
                        </option>
                    ))}
                </select>
            </div>

            <button type="submit">Assign Status</button>
            {message && <p className="message">{message}</p>}
        </form>
    );
};

export default AssignUserStatus;
