
import './ContactUs.css';



const HomePage = () => {
  return (
      <div className="home-page">
          <h1>Welcome FAQ!</h1>
          <p>This is the FAQ page of your application.</p>
          {/* Add more content or styling as needed */}
      </div>
  );
};

export default HomePage;