import React, { useState } from 'react';
import axios from 'axios';
import './FileManagement.css'; // Import the CSS file

const FileManagement = () => {
    const [file, setFile] = useState(null);
    const [data, setData] = useState([]);
    const [dataTypes, setDataTypes] = useState({});
    const [columnNames, setColumnNames] = useState([]);
    const [nullCounts, setNullCounts] = useState({});
    const [rowCount, setRowCount] = useState(0);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [fileUploaded, setFileUploaded] = useState(false); // New state to track if a file has been uploaded

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
        setFileUploaded(false); // Reset file uploaded status when a new file is selected
    };

    const handleFileUpload = async () => {
        if (!file) {
            setError('Please select a file to upload');
            return;
        }

        setLoading(true);
        setError('');

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post('/api/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            console.log('API Response:', response.data); // Log entire response for debugging

            if (response.data.data) {
                setData(response.data.data);
                setDataTypes(response.data.dataTypes || {});
                setColumnNames(response.data.columnNames || []);
                setNullCounts(response.data.nullCounts || {});
                setRowCount(response.data.rowCount || 0);
                setFileUploaded(true); // Mark file as uploaded
            } else {
                setError('No data found in the file');
                setFileUploaded(true); // Mark file as uploaded
            }
        } catch (err) {
            console.error('Error uploading file:', err.message);
            setError('Error uploading file. Please try again.');
            setFileUploaded(true); // Mark file as uploaded
        } finally {
            setLoading(false);
        }
    };

    const handleUploadToDatabase = async () => {
        if (data.length === 0) {
            setError('No data to upload');
            return;
        }

        setLoading(true);
        setError('');

        try {
            const response = await axios.post('/api/upload-to-database', {
                filename: file.name.split('.')[0], // Use filename without extension for table name
                data
            });

            console.log('Database Upload Response:', response.data);
            alert(response.data.message); // Show success message

        } catch (err) {
            console.error('Error uploading data to database:', err.message);
            setError('Error uploading data to database. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="layout-wrapper">
            <div className="main-content">
                <div className="file-management-container">
                    <h2>File Management</h2>
                    <input type="file" onChange={handleFileChange} />
                    <button onClick={handleFileUpload} disabled={loading}>
                        {loading ? 'Uploading...' : 'Upload File'}
                    </button>
                    {error && <p className="error-message">{error}</p>}
                    {!loading && fileUploaded && data.length > 0 && (
                        <div>
                            <p className="file-name">Uploaded File: {file.name}</p>
                            
                            <div className="table-container">
                                <table className="file-management-table">
                                    <thead>
                                        <tr>
                                            {columnNames.map((colName, index) => (
                                                <th key={index}>{colName}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((row, index) => (
                                            <tr key={index}>
                                                {columnNames.map((colName, i) => (
                                                    <td key={i}>{row[colName]}</td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            {/* Data Types Table */}
                            <div className="data-types">
                                <h3>Data Types</h3>
                                <table className="data-types-table">
                                    <thead>
                                        <tr>
                                            <th>Column Name</th>
                                            <th>Data Type</th>
                                            <th>Null Count</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.entries(dataTypes).length > 0 ? (
                                            Object.entries(dataTypes).map(([column, type], index) => (
                                                <tr key={index}>
                                                    <td>{column}</td>
                                                    <td>{type}</td>
                                                    <td>{nullCounts[column] || 0}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="3">No data types available.</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                <p>Total Rows: {rowCount}</p>
                            </div>

                            {/* Upload to Database Button */}
                            <button onClick={handleUploadToDatabase} disabled={loading}>
                                {loading ? 'Uploading to Database...' : 'Upload to Database'}
                            </button>
                        </div>
                    )}
                    {!loading && fileUploaded && data.length === 0 && <p>No data available.</p>}
                </div>
            </div>
        </div>
    );
};

export default FileManagement;
