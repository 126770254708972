import React from 'react';
import aboutImg from '../images/about-img.jpg';
import './AboutUs.css'; // Import the CSS file

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <div className="about-us-image">
        <img src={aboutImg} alt="About Us" />
      </div>
      <div className="about-us-text">
        <h2>About Us</h2>
        <p>
          There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration
          in some form, by injected humour, or randomised words which don't look even slightly believable. If you
          are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in
          the middle of text.
        </p>
        <a href="/">Read More</a>
      </div>
    </div>
  );
};

export default AboutUs;
