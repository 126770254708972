import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';

const AssignTestStatus = ({ onSuccess }) => {

    const [testList, setTestList] = useState([]);
    const [statusList, setStatusList] = useState([]); // New state for statuses

    const [selectedTest, setSelectedTest] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {


                // Fetch the list of Tests
                const fetchTestList = async () => {
                    try {
                        const response = await axios.get('/api/tests');
                        setTestList(response.data);
                    } catch (error) {
                        console.error('Error fetching test list:', error);
                    }
                };

        // Fetch the list of statuses from the backend
        const fetchStatusList = async () => {
            try {
                const response = await axios.get('/api/status');
                setStatusList(response.data); // Assuming response contains the list of statuses
            } catch (error) {
                console.error('Error fetching status list:', error);
            }
        };

 
        fetchTestList();
        fetchStatusList(); // Fetch statuses when the component mounts
    }, []);

    const handleTestChange = (event) => {
        setSelectedTest(event.target.value);
    };
    const handleStatusChange = (event) => {
        setSelectedStatus(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        try {
            await axios.post('/api/admin/assign-teststatus', {
              
                testName: selectedTest, // Include testName here
                status: selectedStatus
            });
    
            setMessage(`The test ${selectedTest} has been assigned the status ${selectedStatus} .`);
            onSuccess();
        } catch (error) {
            console.error('Error assigning test status:', error);
            setMessage('Error assigning test status');
        }
    };
    
    return (
        <form className="admin-form" onSubmit={handleSubmit}>
            <h2>Assign Company Test Status</h2>
            <div className="form-group">
                <label htmlFor="testDropdown">Select Test:</label>
                <select id="testDropdown" value={selectedTest} onChange={handleTestChange}>
                    <option value="">Select a Test</option>
                    {testList.map((test, index) => (
                        <option key={index} value={test.TestName}>
                            {test.TestName}
                        </option>
                    ))}
                </select>
            </div>
            <div className="form-group">
                <label htmlFor="statusDropdown">Select Status:</label>
                <select id="statusDropdown" value={selectedStatus} onChange={handleStatusChange}>
                    <option value="">Select a Status</option>
                    {statusList.map((status, index) => (
                        <option key={index} value={status.StatusID}>
                            {status.StatusName}
                        </option>
                    ))}
                </select>
            </div>

            <button type="submit">Assign Status</button>
            {message && <p className="message">{message}</p>}
        </form>
    );
};

export default AssignTestStatus;
