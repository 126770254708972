// src/pages/Register.js
import React, { useState } from 'react';
import axios from 'axios';
import './Register.css'; // Import your CSS file for styling
import { Link } from 'react-router-dom'; // Import Link from react-router-dom for navigation

const RegisterPage = () => {
    const [formData, setFormData] = useState({
        username: '',
        password: ''
    });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            // Send POST request to backend
            const response = await axios.post('/api/register', formData);
            setSuccess(response.data.message);
            setError('');
        } catch (err) {
            // Check if the error is due to username already existing
            if (err.response && err.response.status === 409) {
                setError(`Username '${formData.username}' already exists. Please choose another name.`);
            } else {
                setError('Registration failed. Please try again.');
            }
            setSuccess('');
        }
    };

    return (
      <div className="login-wrapper"> 
        <div className="register-container">
            <h2>Register</h2>
            {error && <div className="error-message">{error}</div>}
            {success && <div className="success-message">{success}</div>}
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="username">Username</label>
                    <input
                        type="text"
                        id="username"
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                    />
                </div>
                <button type="submit">Register</button>
                Already a Member? <Link to="/Login" className="register-link">Login</Link> {/* Adjust the path if needed */}
            </form>
        </div>
      </div>


    );
};

export default RegisterPage;
