import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Footer.css';

const Footer = () => {
    const [footerContent, setFooterContent] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchFooterContent = async () => {
            try {
                // const response = await axios.get('/api/footer');
                const response = await axios.get('/api/footer');                
                console.log('Footer API response:', response.data); // Log API response
                if (response.data && response.data.content) {
                    setFooterContent(response.data.content); // Use 'content' as key
                } else {
                    setFooterContent('No content available'); // Handle cases where content is missing
                }
                setLoading(false); // Stop loading
            } catch (error) {
                console.error('Error fetching footer content:', error); // Log the error
                setError('Failed to load content'); // Set error message
                setLoading(false); // Stop loading
            }
        };
        

        fetchFooterContent();
    }, []);

    return (
        <footer className="footer">
            <div className="footer-content">
                {loading ? 'Loading...' : error ? error : footerContent} {/* Display content or error */}
            </div>
        </footer>
    );
};

export default Footer;
