import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css'; // Import the overriding styles last
import App from './App';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


const HomePage = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  // Array of texts to show for each dot with HTML content
  const texts = [
    `<div>
        <h1>We Provide Best Healthcare</h1>
        <p>Explicabo esse amet tempora quibusdam laudantium, laborum eaque magnam fugiat hic? Esse dicta aliquid error repudiandae earum suscipit fugiat molestias, veniam, vel architecto veritatis delectus repellat modi impedit sequi.</p>
    </div>`,
    `<div>
        <h1>Discover amazing features and updates.</h1>
        <p>Learn more about the latest additions and improvements.</p>
    </div>`,
    `<div>
        <h1>Contact us for more information and support.</h1>
        <p>We're here to assist you with any inquiries or support you may need.</p>
    </div>`
  ];

  useEffect(() => {
    // Interval to automatically change the text every 5 seconds
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 5000); // Change 5000ms to your preferred interval duration

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [texts.length]);

  const handleDotClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <div className="home-page">
      <div className="fixed-background"></div>
      <div className="scroll-content">
        <div className="scroll-text">
          {/* Render HTML content dynamically */}
          <div dangerouslySetInnerHTML={{ __html: texts[activeIndex] }} />
        </div>
      </div>
      
      {/* Dots Navigation */}
      <div className="dots-nav">
        {texts.map((_, index) => (
          <div
            key={index}
            className={`dot ${activeIndex === index ? 'active' : ''}`}
            onClick={() => handleDotClick(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default HomePage;