import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from '../pages/logo.png';
import { AuthContext } from '../context/AuthContext';
import './Header.css';

const Header = () => {
    const [headers, setHeaders] = useState([]);
    const location = useLocation();
    const navigate = useNavigate(); 
    const { auth, logout: authLogout } = useContext(AuthContext);

    useEffect(() => {
        const fetchHeaders = async () => {
            try {
                const pageName = getPageNameFromLocation(location.pathname);
                if (pageName) {
                    const response = await axios.get(`/api/headers/${pageName}`);
                    setHeaders(response.data);
                }
            } catch (error) {
                console.error('Error fetching headers:', error);
            }
        };
        fetchHeaders();
    }, [location]);

    const getPageNameFromLocation = (pathname) => {
        const pageMappings = {
            '/about-us': 'AboutUs',
            '/contact-us': 'ContactUs',
            '/dashboard': 'Dashboard',
            '/orgdashboard': 'OrgDashboard',
            '/filemanagement': 'FileManagement',
            '/orgfilemanagement': 'OrgFileManagement',
            '/myreport': 'MyReport',
            '/empprofile': 'EmpProfile',
            '/howto': 'HowTo',
            '/admin': 'Admin',
            '/orgadmin': 'OrgAdmin',
            '/usersettings': 'UserSettings',
            '/adminsettings': 'AdminSettings',
            '/orgadminsettings': 'OrgAdminSettings',
        };
        return pageMappings[pathname] || 'Index'; 
    };

    const handleLogout = () => {
        authLogout(); 
        navigate('/'); 
    };

    return (
        <header className="header">
            <nav>
                <div className="header-left">
                <img src={logo} alt="Logo" className="logo" />
                </div>
                <div className="header-center">
                    <ul>
                        {headers.map((header, index) => (
                            <li key={index} className={location.pathname === header.Path ? 'active' : ''}>
                                <Link to={header.Path}>{header.Name}</Link>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="header-right">
                    {auth ? (
                         <div className="auth-info">
                         <div className="user-details">
                             <span className="user-name">Welcome, {auth.name}</span>
                             {auth.role && <span className="user-role"> ({auth.role}) </span>}
                         </div>
                         <button className="logout-button" onClick={handleLogout}>
                             Logout
                         </button>
                     </div>
                        
                    ) : (
                        <Link to="/login">Login</Link>
                    )} 
                </div>
            </nav>
        </header>
    );
};

export default Header;
