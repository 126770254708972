import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import './MyReport.css';
import { Link } from 'react-router-dom';  // <-- Add this import

const MyReport = () => {
    const { auth } = useContext(AuthContext);
    const [reportData, setReportData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [companyName, setCompanyName] = useState('');

    useEffect(() => {
        const fetchReport = async () => {
            if (!auth || !auth.name) {
                setError('User not authenticated');
                setLoading(false); // Make sure to stop loading
                return;
            }

            try {
                const response = await axios.get('/api/myreport', {
                    params: { username: auth.name }
                });
                
                console.log('API response:', response.data); // Debug the response data

                // Ensure response.data has the expected structure
                setReportData(response.data);
                setCompanyName(response.data['CompanyName'] || 'No Company Name Available');
            } catch (err) {
                console.error('Error fetching report:', err);
                setError('Failed to fetch report data');
            } finally {
                setLoading(false); // Ensure loading is set to false
            }
        };

        fetchReport();
    }, [auth]);

    const getSymbol = (category) => {
        let symbol;
        let color;
    
        // Check if the category starts with "Unknown" to generalize all unknown cases
        if (category.startsWith('Unknown')) {
            symbol = '■'; // Dark blue square
            color = 'deeppink';
        } else {
            switch (category) {
                case 'Normal':
                    symbol = '▲'; // Green upward triangle
                    color = 'darkgreen';
                    break;
                case 'Moderately Abnormal':
                    symbol = '▲'; // Orange upward triangle
                    color = 'orange';
                    break;
                case 'Abnormal':
                    symbol = '▼'; // Red downward triangle
                    color = 'darkred';
                    break;
                default:
                    symbol = ''; // No symbol if none matches
                    color = 'black';
                    break;
            }
        }
    
        const symbolStyle = {
            color: color,
            fontSize: '20px', // Adjust the font size to make the symbols larger
            marginLeft: '10px',
            display: 'inline-block',
        };
    
        return <span style={symbolStyle}>{symbol}</span>;
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <div className="my-report-container">
            {error && <div className="error">{error}</div>}
            {reportData ? (
                <>
                    <h3>Company Name: <span className="company-name">{companyName}</span></h3>
                    <div className="kpi-container">

                        
                        {/* Fasting Blood Sugar KPI */}
                        <div className="kpi-item">
                            <h3 className="kpi-header">Fasting Blood Sugar</h3>
                            {/* Apply the CSS class for highlighting */}
   
                            <div className="kpi-content">
                           
                                <p className="hover-info">
                                {reportData['FBS']} <br/>
                                    {reportData['FBS'] ? (
                                        <>
                                            {reportData['Fasting Blood Sugar']}
                                            {getSymbol(
                                                reportData['Fasting Blood Sugar'],
                                                'Unknown FBS',
                                                'Normal',
                                                'Moderately Abnormal',
                                                'Abnormal'
                                            )}
                                        </>
                                    ) : (
                                        'No Data'
                                    )}
                                    <span className="tooltip">
                                        <div className="tooltip-content">
                                            {reportData['FBS']}<br />
                                            Normal: 70-110<br />
                                            Moderately Abnormal: 111-150<br />
                                            Abnormal: &gt; 151
                                        </div>
                                    </span>
                                </p>
                            </div>
                            <Link
                                to={{
                                    pathname: '/FastingBloodSugar',
                                    state: { kpiName: 'Fasting Blood Sugar', kpiValue: reportData['FBS'] },
                                }}
                                className="more-details-link"
                            >
                                More Details?
                            </Link>
                        </div>
            
            {/* Random Blood Sugar KPI */}
            <div className="kpi-item">
                    <h3 className="kpi-header">Random Blood Sugar</h3>
                   
                    <div className="kpi-content">
                        <p className="hover-info">
                        {reportData['RBSBloodSugar']} <br/>
                            {reportData['Random Blood Sugar'] ? (
                                <>
                                    {reportData['Random Blood Sugar']}
                                    {getSymbol(
                                        reportData['Random Blood Sugar'],
                                        'Unknown FBS',
                                        'Normal',
                                        'Moderately Abnormal',
                                        'Abnormal'
                                    )}
                                </>
                            ) : (
                                'No Data'
                            )}
                            <span className="tooltip">
                                <div className="tooltip-content">
                                    {reportData['RBSBloodSugar']}<br />
                                    Normal: 70-160<br />
                                    Moderately Abnormal: 161-250<br />
                                    Abnormal: &gt; 250
                                </div>
                            </span>
                        </p>
                    </div>
                    <Link
                        to={{
                            pathname: '/RandomBloodSugar',
                            state: { kpiName: 'Random Blood Sugar', kpiValue: reportData['Random Blood Sugar'] },
                        }}
                        className="more-details-link"
                    >
                        More Details?
                    </Link>
                </div>

   {/* Post Prendial Blood Sugar KPI */}
   <div className="kpi-item">
                    <h3 className="kpi-header">Post Prendial Blood Sugar</h3>
                   
                    <div className="kpi-content">
                        <p className="hover-info">
                        {reportData['PPBS']} <br/>
                            {reportData['PPBS'] ? (
                                <>
                                    {reportData['Post Prendial Blood Sugar']}
                                    {getSymbol(
                                        reportData['Post Prendial Blood Sugar'],
                                        'Unknown FBS',
                                        'Normal',
                                        'Moderately Abnormal',
                                        'Abnormal'
                                    )}
                                </>
                            ) : (
                                'No Data'
                            )}
                            <span className="tooltip">
                                <div className="tooltip-content">
                                    {reportData['PPBS']}<br />
                                    Normal: 70-160<br />
                                    Moderately Abnormal: 161-250<br />
                                    Abnormal: &gt; 250
                                </div>
                            </span>
                        </p>
                    </div>
                    <Link
                        to={{
                            pathname: '/PostPrendialBloodSugar',
                            state: { kpiName: 'RaPost Prendial Blood Sugar', kpiValue: reportData['Post Prendial Blood Sugar'] },
                        }}
                        className="more-details-link"
                    >
                        More Details?
                    </Link>
                </div>

              {/* Cholesterol Blood Sugar KPI */}
        <div className="kpi-item">
                    <h3 className="kpi-header">Cholesterol</h3>
                   
                    <div className="kpi-content">
                        <p className="hover-info">
                        {reportData['Cholesterolno']} <br/>
                            {reportData['Cholesterolno'] ? (
                                <>
                                    {reportData['Cholesterol']}
                                    {getSymbol(
                                        reportData['Cholesterol'],
                                        'Unknown FBS',
                                        'Normal',
                                        'Moderately Abnormal',
                                        'Abnormal'
                                    )}
                                </>
                            ) : (
                                'No Data'
                            )}
                            <span className="tooltip">
                                <div className="tooltip-content">
                                    {reportData['Cholesterolno']}<br />
                                    Normal: &lt; 200<br />
                                    Moderately Abnormal: 201-270<br />
                                    Abnormal: &gt; 270
                                </div>
                            </span>
                        </p>
                    </div>
                    <Link
                        to={{
                            pathname: '/Cholesterol',
                            state: { kpiName: 'Cholesterol', kpiValue: reportData['Cholesterol'] },
                        }}
                        className="more-details-link"
                    >
                        More Details?
                    </Link>
                </div>

                  {/* Bilirubin - Total Blood Sugar KPI */}
        <div className="kpi-item">
                    <h3 className="kpi-header">Bilirubin - Total</h3>
                   
                    <div className="kpi-content">
                        <p className="hover-info">
                        {reportData['SBilirubinTotal']} <br/>
                                 {reportData['SBilirubinTotal'] ? (
                                <>
                                    {reportData['Bilirubin - Total']}
                                    {getSymbol(
                                        reportData['Bilirubin - Total'],
                                        'Unknown FBS',
                                        'Normal',
                                        'Moderately Abnormal',
                                        'Abnormal'
                                    )}
                                </>
                            ) : (
                                'No Data'
                            )}
                            <span className="tooltip">
                                <div className="tooltip-content">
                                    {reportData['SBilirubinTotal']}<br />
                                    Normal: 0.2-1<br />
                                    Moderately Abnormal: 1.1-2.5<br />
                                    Abnormal: &gt; 2.5
                                </div>
                            </span>
                        </p>
                    </div>
                    <Link
                        to={{
                            pathname: '/SBilirubinTotal',
                            state: { kpiName: 'Bilirubin - Total', kpiValue: reportData['Bilirubin - Total'] },
                        }}
                        className="more-details-link"
                    >
                        More Details?
                    </Link>
                </div>
                    
                    {/*Serum Blood Urea KPI */}
        <div className="kpi-item">
                    <h3 className="kpi-header">Serum Blood Urea</h3>
                   
                    <div className="kpi-content">
                        <p className="hover-info">
                        {reportData['Serum Blood Urea']} <br/>
                            {reportData['Serum Blood Urea'] ? (
                                <>
                                    {reportData['Serum Blood Urea']}
                                    {getSymbol(
                                        reportData['Serum Blood Urea'],
                                        'Unknown FBS',
                                        'Normal',
                                        'Moderately Abnormal',
                                        'Abnormal'
                                    )}
                                </>
                            ) : (
                                'No Data'
                            )}
                            <span className="tooltip">
                                <div className="tooltip-content">
                                    {reportData['Serum Blood Urea']}<br />
                                    Normal: 19-44<br />
                                    Moderately Abnormal: 45-60<br />
                                    Abnormal: &gt; 60
                                </div>
                            </span>
                        </p>
                    </div>
                    <Link
                        to={{
                            pathname: '/SerumBloodUrea',
                            state: { kpiName: 'Serum Blood Urea', kpiValue: reportData['Serum Blood Urea'] },
                        }}
                        className="more-details-link"
                    >
                        More Details?
                    </Link>
                </div>

                 {/* Serum Creatinine KPI */}
        <div className="kpi-item">
                    <h3 className="kpi-header">Serum Creatinine</h3>
                    
                    <div className="kpi-content">
                        <p className="hover-info">
                        {reportData['Serum Creatinine']} <br/>
                            {reportData['Serum Creatinine'] ? (
                                <>
                                    {reportData['Serum Creatinine']}
                                    {getSymbol(
                                        reportData['Serum Creatinine'],
                                        'Unknown FBS',
                                        'Normal',
                                        'Moderately Abnormal',
                                        'Abnormal'
                                    )}
                                </>
                            ) : (
                                'No Data'
                            )}
                            <span className="tooltip">
                                <div className="tooltip-content">
                                    {reportData['Serum Creatinine']}<br />
                                    Normal: 0.6-1.4<br />
                                    Moderately Abnormal: 1.5-3<br />
                                    Abnormal: &gt; 3
                                </div>
                            </span>
                        </p>
                    </div>
                    <Link
                        to={{
                            pathname: '/SerumCreatinine',
                            state: { kpiName: 'Serum Creatinine', kpiValue: reportData['Serum Creatinine'] },
                        }}
                        className="more-details-link"
                    >
                        More Details?
                    </Link>
                </div>

                    {/* Serum Uric Acid KPI */}
        <div className="kpi-item">
                    <h3 className="kpi-header">Serum Uric Acid</h3>
                   
                    <div className="kpi-content">
                        <p className="hover-info">
                        {reportData['Serum Uric Acid']} <br/>
                            {reportData['Serum Uric Acid'] ? (
                                <>
                                    {reportData['Serum Uric Acid']}
                                    {getSymbol(
                                        reportData['Serum Uric Acid'],
                                        'Unknown FBS',
                                        'Normal',
                                        'Moderately Abnormal',
                                        'Abnormal'
                                    )}
                                </>
                            ) : (
                                'No Data'
                            )}
                            <span className="tooltip">
                                <div className="tooltip-content">
                                    Male:
                                    {reportData['Serum Uric Acid']}<br />
                                    Normal: 3.5-7.2<br />
                                    Moderately Abnormal: 7.3-15<br />
                                    Abnormal: &gt; 15<br />
                                    Female:
                                    {reportData['Serum Uric Acid']}<br />
                                    Normal: 2.6-6<br />
                                    Moderately Abnormal: 6.1-12<br />
                                    Abnormal: &gt; 12
                                </div>
                            </span>
                        </p>
                    </div>
                    <Link
                        to={{
                            pathname: '/SerumUricAcid',
                            state: { kpiName: 'Serum Uric Acid', kpiValue: reportData['Serum Uric Acid'] },
                        }}
                        className="more-details-link"
                    >
                        More Details?
                    </Link>
                </div>

                    {/*Haemoglobin KPI */}
        <div className="kpi-item">
                    <h3 className="kpi-header">Haemoglobin</h3>
                   
                    <div className="kpi-content">
                        <p className="hover-info">
                        {reportData['Haemoglobin']} <br/>
                            {reportData['Haemoglobin'] ? (
                                <>
                                    {reportData['Haemoglobin']}
                                    {getSymbol(
                                        reportData['Haemoglobin'],
                                        'Unknown FBS',
                                        'Normal',
                                        'Moderately Abnormal',
                                        'Abnormal'
                                    )}
                                </>
                            ) : (
                                'No Data'
                            )}
                            <span className="tooltip">
                                <div className="tooltip-content">
                                Male:
                                    {reportData['Haemoglobin']}<br />
                                    Normal: 13-17<br />
                                    Moderately Abnormal: &lt; 13 <br />
                                    Abnormal: &lt; 10 <br />
                                    Femle:<br />
                                    Normal: 12-16<br />
                                    Moderately Abnormal: &lt; 12 <br />
                                    Abnormal: &lt; 10
                                </div>
                            </span>
                        </p>
                    </div>
                    <Link
                        to={{
                            pathname: '/Haemoglobin',
                            state: { kpiName: 'Haemoglobin', kpiValue: reportData['Haemoglobin'] },
                        }}
                        className="more-details-link"
                    >
                        More Details?
                    </Link>
                </div>

                     {/* Thyroid Function Test (T3) KPI */}
        <div className="kpi-item">
                    <h3 className="kpi-header">Thyroid Function Test (T3)</h3>
                    
                    <div className="kpi-content">
                        <p className="hover-info">
                        {reportData['Thyroid Function Test (T3)']} <br/>
                            {reportData['Thyroid Function Test (T3)'] ? (
                                <>
                                    {reportData['Thyroid Function Test (T3)']}
                                    {getSymbol(
                                        reportData['Thyroid Function Test (T3)'],
                                        'Unknown FBS',
                                        'Normal',
                                        'Moderately Abnormal',
                                        'Abnormal'
                                    )}
                                </>
                            ) : (
                                'No Data'
                            )}
                            <span className="tooltip">
                                <div className="tooltip-content">
                                    {reportData['Thyroid Function Test (T3)']}<br />
                                    Normal: 0.8-2.1<br />
                                    Moderately Abnormal: &lt; 0.8 or &gt; 2.1<br />
                                    Abnormal: &lt; 0.8 or &gt; 2.1
                                </div>
                            </span>
                        </p>
                    </div>
                    <Link
                        to={{
                            pathname: '/ThyroidFunctionTest(T3)',
                            state: { kpiName: 'Thyroid Function Test (T3)', kpiValue: reportData['Thyroid Function Test (T3)'] },
                        }}
                        className="more-details-link"
                    >
                        More Details?
                    </Link>
                </div>

            
                     {/* Thyroid Function Test (T4) KPI */}
        <div className="kpi-item">
                    <h3 className="kpi-header">Thyroid Function Test (T4)</h3>
                   
                    <div className="kpi-content">
                        <p className="hover-info">
                        {reportData['Thyroid Function Test (T4)']} <br/>
                            {reportData['Thyroid Function Test (T4)'] ? (
                                <>
                                    {reportData['Thyroid Function Test (T4)']}
                                    {getSymbol(
                                        reportData['Thyroid Function Test (T4)'],
                                        'Unknown FBS',
                                        'Normal',
                                        'Moderately Abnormal',
                                        'Abnormal'
                                    )}
                                </>
                            ) : (
                                'No Data'
                            )}
                            <span className="tooltip">
                                <div className="tooltip-content">
                                    {reportData['Thyroid Function Test (T4)']}<br />
                                    Normal: 3.2-12.7<br />
                                    Moderately Abnormal: &lt; 3.2 or &gt; 12.7<br />
                                    Abnormal: &lt; 3.2 or &gt; 12.7
                                </div>
                            </span>
                        </p>
                    </div>
                    <Link
                        to={{
                            pathname: '/ThyroidFunctionTest(T4)',
                            state: { kpiName: 'Thyroid Function Test (T4)', kpiValue: reportData['Thyroid Function Test (T4)'] },
                        }}
                        className="more-details-link"
                    >
                        More Details?
                    </Link>
                </div>

                   {/* Thyroid Function Test (TSH) KPI */}
        <div className="kpi-item">
                    <h3 className="kpi-header">Thyroid Function Test (TSH)</h3>
                  
                    <div className="kpi-content">
                        <p className="hover-info">
                        {reportData['Thyroid Function Test (TSH)']} <br/>
                            {reportData['Thyroid Function Test (TSH)'] ? (
                                <>
                                    {reportData['Thyroid Function Test (TSH)']}
                                    {getSymbol(
                                        reportData['Thyroid Function Test (TSH)'],
                                        'Unknown FBS',
                                        'Normal',
                                        'Moderately Abnormal',
                                        'Abnormal'
                                    )}
                                </>
                            ) : (
                                'No Data'
                            )}
                            <span className="tooltip">
                                <div className="tooltip-content">
                                    {reportData['Thyroid Function Test (TSH)']}<br />
                                    Normal: 0.3-4.5<br />
                                    Moderately Abnormal: &lt; 0.3 or &gt; 4.5<br />
                                    Abnormal: &lt; 0.3 or &gt; 4.5
                                </div>
                            </span>
                        </p>
                    </div>
                    <Link
                        to={{
                            pathname: '/ThyroidFunctionTest(TSH)',
                            state: { kpiName: 'Thyroid Function Test (TSH)', kpiValue: reportData['Thyroid Function Test (TSH)'] },
                        }}
                        className="more-details-link"
                    >
                        More Details?
                    </Link>
                </div>

                    {/* Vitamin D, 25 - Hydroxy KPI */}
        <div className="kpi-item">
                    <h3 className="kpi-header">Vitamin D, 25 - Hydroxy</h3>
                    
                    <div className="kpi-content">
                        <p className="hover-info">
                        {reportData['VitaminD,25-Hydroxy']} <br/>
                            {reportData['Vitamin D, 25 - Hydroxy'] ? (
                                <>
                                    {reportData['Vitamin D, 25 - Hydroxy']}
                                    {getSymbol(
                                        reportData['Vitamin D, 25 - Hydroxy'],
                                        'Unknown FBS',
                                        'Normal',
                                        'Moderately Abnormal',
                                        'Abnormal'
                                    )}
                                </>
                            ) : (
                                'No Data'
                            )}
                            <span className="tooltip">
                                <div className="tooltip-content">
                                    {reportData['VitaminD,25-Hydroxy']}<br />
                                    Normal: 30-100<br />
                                    Moderately Abnormal: 0-30<br />
                                    Abnormal: &gt; 100
                                </div>
                            </span>
                        </p>
                    </div>
                    <Link
                        to={{
                            pathname: '/VitaminD25Hydroxy',
                            state: { kpiName: 'Vitamin D, 25 - Hydroxy', kpiValue: reportData['Vitamin D, 25 - Hydroxy'] },
                        }}
                        className="more-details-link"
                    >
                        More Details?
                    </Link>
                </div>

                   {/* Vitamin - B12 KPI */}
        <div className="kpi-item">
                    <h3 className="kpi-header">Vitamin - B12</h3>
                    
                    <div className="kpi-content">
                        <p className="hover-info">
                        {reportData['VitaminB12']} <br/>
                            {reportData['Vitamin - B12'] ? (
                                <>
                                    {reportData['Vitamin - B12']}
                                    {getSymbol(
                                        reportData['Vitamin - B12'],
                                        'Unknown FBS',
                                        'Normal',
                                        'Moderately Abnormal',
                                        'Abnormal'
                                    )}
                                </>
                            ) : (
                                'No Data'
                            )}
                            <span className="tooltip">
                                <div className="tooltip-content">
                                    {reportData['VitaminB12']}<br />
                                    Normal: 211-911<br />
                                    Moderately Abnormal: 145-180<br />
                                    Abnormal: &gt; 180
                                </div>
                            </span>
                        </p>
                    </div>
                    <Link
                        to={{
                            pathname: '/VitaminB12',
                            state: { kpiName: 'Vitamin - B12', kpiValue: reportData['Vitamin - B12'] },
                        }}
                        className="more-details-link"
                    >
                        More Details?
                    </Link>
                </div>

                     {/* HIV KPI */}
        <div className="kpi-item">
                    <h3 className="kpi-header">HIV</h3>
                    
                    <div className="kpi-content">
                        <p className="hover-info">
                        {reportData['HIV']} <br/>
                            {reportData['HIV'] ? (
                                <>
                                    {reportData['HIV']}
                                    {getSymbol(
                                        reportData['HIV'],
                                        'Unknown FBS',
                                        'Normal',
                                        'Moderately Abnormal',
                                        'Abnormal'
                                    )}
                                </>
                            ) : (
                                'No Data'
                            )}
                            <span className="tooltip">
                                <div className="tooltip-content">
                                    {reportData['HIV']}<br />
                                    Normal: Negative / Non-Reactive<br />
                                    Abnormal: Positive / Reactive
                                </div>
                            </span>
                        </p>
                    </div>
                    <Link
                        to={{
                            pathname: '/HIV',
                            state: { kpiName: 'HIV', kpiValue: reportData['HIV'] },
                        }}
                        className="more-details-link"
                    >
                        More Details?
                    </Link>
                </div>

    {/* HbsAg KPI */}
    <div className="kpi-item">
                    <h3 className="kpi-header">HbsAg</h3>
                    
                    <div className="kpi-content">
                        <p className="hover-info">
                        {reportData['HbsAg']} <br/>
                            {reportData['HbsAg'] ? (
                                <>
                                    {reportData['HbsAg']}
                                    {getSymbol(
                                        reportData['HbsAg'],
                                        'Unknown FBS',
                                        'Normal',
                                        'Moderately Abnormal',
                                        'Abnormal'
                                    )}
                                </>
                            ) : (
                                'No Data'
                            )}
                            <span className="tooltip">
                                <div className="tooltip-content">
                                    {reportData['HbsAg']}<br />
                                    Normal: Negative / Non-Reactive<br />
                                    Abnormal: Positive / Reactive
                                </div>
                            </span>
                        </p>
                    </div>
                    <Link
                        to={{
                            pathname: '/HbsAg',
                            state: { kpiName: 'HbsAg', kpiValue: reportData['HbsAg'] },
                        }}
                        className="more-details-link"
                    >
                        More Details?
                    </Link>
                </div>

                     {/* Audiometry KPI */}
        <div className="kpi-item">
                    <h3 className="kpi-header">Audiometry</h3>
                   
                    <div className="kpi-content">
                        <p className="hover-info">
                        {reportData['Audiometry']}<br/>
                            {reportData['Audiometry'] ? (
                                <>
                                    {reportData['Audiometry']}
                                    {getSymbol(
                                        reportData['Audiometry'],
                                        'Unknown FBS',
                                        'Normal',
                                        'Moderately Audiometry',
                                        'Abnormal'
                                    )}
                                </>
                            ) : (
                                'No Data'
                            )}
                            <span className="tooltip">
                                <div className="tooltip-content">
                                    {reportData['Audiometry']}<br />
                                    Normal: Normal<br />
                                    Abnormal: Abnormal
                                </div>
                            </span>
                        </p>
                    </div>
                    <Link
                        to={{
                            pathname: '/Audiometry',
                            state: { kpiName: 'Audiometry', kpiValue: reportData['Audiometry'] },
                        }}
                        className="more-details-link"
                    >
                        More Details?
                    </Link>
                </div>

                  
                       {/* Vision Test KPI */}
        <div className="kpi-item">
                    <h3 className="kpi-header">Vision Test</h3>
                    
                    <div className="kpi-content">
                        <p className="hover-info">
                        {reportData['VisionTest']} <br/>
                            {reportData['Vision Test'] ? (
                                <>
                                    {reportData['Vision Test']}
                                    {getSymbol(
                                        reportData['Vision Test'],
                                        'Unknown FBS',
                                        'Normal',
                                        'Moderately Audiometry',
                                        'Abnormal'
                                    )}
                                </>
                            ) : (
                                'No Data'
                            )}
                            <span className="tooltip">
                                <div className="tooltip-content">
                                    {reportData['VisionTest']}<br />
                                    Normal: Normal<br />
                                    Abnormal: Abnormal
                                </div>
                            </span>
                        </p>
                    </div>
                    <Link
                        to={{
                            pathname: '/Vision Test',
                            state: { kpiName: 'Vision Test', kpiValue: reportData['Vision Test'] },
                        }}
                        className="more-details-link"
                    >
                        More Details?
                    </Link>
                </div>

                         {/* Lung Function Test: PFT KPI */}
        <div className="kpi-item">
                    <h3 className="kpi-header">Lung Function Test: PFT</h3>
                   
                    <div className="kpi-content">
                        <p className="hover-info">
                        {reportData['Lung Function Test: PFT']} <br/>
                            {reportData['Lung Function Test: PFT'] ? (
                                <>
                                    {reportData['Lung Function Test: PFT']}
                                    {getSymbol(
                                        reportData['Lung Function Test: PFT'],
                                        'Unknown FBS',
                                        'Normal',
                                        'Moderately Audiometry',
                                        'Abnormal'
                                    )}
                                </>
                            ) : (
                                'No Data'
                            )}
                            <span className="tooltip">
                                <div className="tooltip-content">
                                    {reportData['Lung Function Test: PFT']}<br />
                                    Normal: Normal<br />
                                    Abnormal: Abnormal
                                </div>
                            </span>
                        </p>
                    </div>
                    <Link
                        to={{
                            pathname: '/LungFunctionTestPFT',
                            state: { kpiName: 'Lung Function Test: PFT', kpiValue: reportData['Lung Function Test: PFT'] },
                        }}
                        className="more-details-link"
                    >
                        More Details?
                    </Link>
                </div>

                           {/* X RAY KPI */}
        <div className="kpi-item">
                    <h3 className="kpi-header">X RAY</h3>
                   
                    <div className="kpi-content">
                        <p className="hover-info">
                        {reportData['X RAY']} <br/>
                            {reportData['X RAY'] ? (
                                <>
                                    {reportData['X RAY']}
                                    {getSymbol(
                                        reportData['X RAY'],
                                        'Unknown FBS',
                                        'Normal',
                                        'Moderately Audiometry',
                                        'Abnormal'
                                    )}
                                </>
                            ) : (
                                'No Data'
                            )}
                            <span className="tooltip">
                                <div className="tooltip-content">
                                    {reportData['X RAY']}<br />
                                    Normal: Normal<br />
                                    Abnormal: Abnormal
                                </div>
                            </span>
                        </p>
                    </div>
                    <Link
                        to={{
                            pathname: '/XRAY',
                            state: { kpiName: 'X RAY', kpiValue: reportData['X RAY'] },
                        }}
                        className="more-details-link"
                    >
                        More Details?
                    </Link>
                </div>

                             {/* BP KPI */}
        <div className="kpi-item">
                    <h3 className="kpi-header">BP</h3>
                    
                    <div className="kpi-content">
                        <p className="hover-info">
                        {reportData['BP']} <br/>
                            {reportData['BP'] ? (
                                <>
                                    {reportData['BP']}
                                    {getSymbol(
                                        reportData['BP'],
                                        'Unknown FBS',
                                        'Normal',
                                        'Moderately Abnormal',
                                        'Abnormal'
                                    )}
                                </>
                            ) : (
                                'No Data'
                            )}
                            <span className="tooltip">
                                <div className="tooltip-content">
                                    {reportData['BP']}<br />
                                    Normal: 90/60-130/90<br />
                                    Abnormal: &lt; 90/60 or &gt; 130/90
                                </div>
                            </span>
                        </p>
                    </div>
                    <Link
                        to={{
                            pathname: '/BP',
                            state: { kpiName: 'BP', kpiValue: reportData['BP'] },
                        }}
                        className="more-details-link"
                    >
                        More Details?
                    </Link>
                </div>

                             {/* BMI KPI */}
        <div className="kpi-item">
                    <h3 className="kpi-header">BMI</h3>
                  
                    <div className="kpi-content">
                        <p className="hover-info">
                        {reportData['BMI']} <br/>
                            {reportData['BMI'] ? (
                                <>
                                    {reportData['BMI']}
                                    {getSymbol(
                                        reportData['BMI'],
                                        'Unknown FBS',
                                        'Normal',
                                        'Moderately Abnormal',
                                        'Abnormal'
                                    )}
                                </>
                            ) : (
                                'No Data'
                            )}
                            <span className="tooltip">
                                <div className="tooltip-content">
                                    {reportData['BMI']}<br />
                                    Normal: 0-30<br />
                                    Abnormal: &gt; 30
                                </div>
                            </span>
                        </p>
                    </div>
                    <Link
                        to={{
                            pathname: '/BMI',
                            state: { kpiName: 'BMI', kpiValue: reportData['BMI'] },
                        }}
                        className="more-details-link"
                    >
                        More Details?
                    </Link>
                </div>
                    </div>
                </>
            ) : (
                <p>No Data Available</p>
            )}
        </div>
    );
};

export default MyReport;
