import React, { useState, useContext } from 'react';
import axios from 'axios';
import './OrgAdminSettings.css'; // Import the CSS file
import { AuthContext } from '../context/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const OrgAdminSettings = () => {
    const { auth } = useContext(AuthContext); // Assuming you have an auth context to get logged-in user's details
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [message, setMessage] = useState('');
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if the new password and confirm password are the same
        if (newPassword !== confirmNewPassword) {
            setMessage('New passwords do not match');
            return;
        }

        try {
            const response = await axios.post('/api/user/change-password', {
                username: auth.name, // Assuming you store the username in auth context
                currentPassword,
                newPassword,
            });

            if (response.data.success) {
                setMessage('Password changed successfully');
            } else {
                setMessage(response.data.message || 'Error changing password');
            }
        } catch (error) {
            // Display specific error messages from the server
            if (error.response && error.response.data && error.response.data.message) {
                setMessage(error.response.data.message);
            } else {
                setMessage('Error updating password');
            }
            console.error('Error:', error);
        }
    };


    return (
        <div className="user-settings-container">
            <h1>Change My Password</h1>
            <form className="user-settings-form" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="currentPassword">Current Password:</label>
                    <div className="password-input">
                        <input
                            type={showCurrentPassword ? 'text' : 'password'}
                            id="currentPassword"
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                            required
                        />
                        <FontAwesomeIcon
                            icon={showCurrentPassword ? faEyeSlash : faEye}
                            className="password-toggle-icon"
                            onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="newPassword">New Password:</label>
                    <div className="password-input">
                        <input
                            type={showNewPassword ? 'text' : 'password'}
                            id="newPassword"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                        />
                        <FontAwesomeIcon
                            icon={showNewPassword ? faEyeSlash : faEye}
                            className="password-toggle-icon"
                            onClick={() => setShowNewPassword(!showNewPassword)}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="confirmNewPassword">Confirm New Password:</label>
                    <div className="password-input">
                        <input
                            type={showConfirmPassword ? 'text' : 'password'}
                            id="confirmNewPassword"
                            value={confirmNewPassword}
                            onChange={(e) => setConfirmNewPassword(e.target.value)}
                            required
                        />
                        <FontAwesomeIcon
                            icon={showConfirmPassword ? faEyeSlash : faEye}
                            className="password-toggle-icon"
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        />
                    </div>
                </div>
                <button type="submit">Change Password</button>
                {message && <p className={`message ${message === 'New passwords do not match' ? 'error' : ''}`}>{message}</p>}
            </form>
        </div>
    );
};

export default OrgAdminSettings;
