import React, { useEffect } from 'react';
import axios from 'axios'; // Import axios
import './FileManagement.css'; // Add your styles here

const FileManagement = () => {


    useEffect(() => {
        const fetchHeaders = async () => {
            try {
                const response = await axios.get(`/api/headers/FileManagement`);
                console.log('Headers:', response.data); // Log the headers for debugging
                // Update state with the fetched headers
            } catch (error) {
                console.error('Error fetching headers:', error);
            }
        };
    
        fetchHeaders();
    }, []);
    return (
        <main className="file-management-content">
            <h2>Manage Your Files</h2>
            <p>This is where you can upload, download, and manage your files.</p>
            {/* Add file management functionality here */}
        </main>
    );
};

export default FileManagement;
