import React, { useState } from 'react';
import axios from 'axios';
import './FileManagement.css'; // Import the CSS file

const FileManagement = () => {
    const [file, setFile] = useState(null);
    const [data, setData] = useState([]);
    const [dataTypes, setDataTypes] = useState({});
    const [columnNames, setColumnNames] = useState([]);
    const [nullCounts, setNullCounts] = useState({});
    const [rowCount, setRowCount] = useState(0);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [fileUploaded, setFileUploaded] = useState(false);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
        setFileUploaded(false); // Reset file uploaded status when a new file is selected
    };

    const handleFileUpload = async () => {
        if (!file) {
            setError('Please select a file to upload');
            return;
        }

        setLoading(true);
        setError('');

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post('/api/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.data) {
                setData(response.data.data);
                setDataTypes(response.data.dataTypes || {});
                setColumnNames(response.data.columnNames || []);
                setNullCounts(response.data.nullCounts || {});
                setRowCount(response.data.rowCount || 0);
                setFileUploaded(true); // Mark file as uploaded
            } else {
                setError('No data found in the file');
                setFileUploaded(true); // Mark file as uploaded
            }
        } catch (err) {
            setError('Error uploading file. Please try again.');
            setFileUploaded(true); // Mark file as uploaded
        } finally {
            setLoading(false);
        }
    };

    const handleUploadToDatabase = async () => {
        if (data.length === 0) {
            setError('No data to upload');
            return;
        }

        setLoading(true);
        setError('');

        try {
            const response = await axios.post('/api/upload-to-database', {
                filename: file.name.split('.')[0], // Use filename without extension for table name
                data
            });

            alert(response.data.message); // Show success message
        } catch (err) {
            setError('Error uploading data to database. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    // Function to handle extracting and uploading distinct EmpName values to Users table
    const handleUploadUsers = async () => {
        // Extract distinct EmpName from data
        const empNames = [...new Set(data.map(row => row.EmpName))];

        if (empNames.length === 0) {
            setError('No EmpName data found.');
            return;
        }

        setLoading(true);
        setError('');

        try {
            const response = await axios.post('/api/upload-users', {
                empNames
            });

            alert(response.data.message); // Show success message
        } catch (err) {
            setError('Error uploading users. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    // Function to handle extracting and uploading distinct CompanyName values to CompanyDetails table
 // Function to handle extracting and uploading distinct CompanyName values to CompanyDetails table
const handleUploadCompanies = async () => {
    // Extract distinct CompanyName from data
    const companyNames = [...new Set(data.map(row => row.CompanyName))];

    if (companyNames.length === 0) {
        setError('No CompanyName data found.');
        return;
    }

    setLoading(true);
    setError('');

    try {
        const response = await axios.post('/api/upload-companies', {
            companyNames: companyNames.map(name => ({ CompanyName: name })) // Wrap in objects
        });

        alert(response.data.message); // Show success message
    } catch (err) {
        setError('Error uploading companies. Please try again.');
    } finally {
        setLoading(false);
    }
};

    
    

    return (
        <div className="layout-wrapper">
            <div className="main-content">
                <div className="file-management-container">
                    <h2>File Management</h2>
                    <input type="file" onChange={handleFileChange} />
                    <button onClick={handleFileUpload} disabled={loading}>
                        {loading ? 'Uploading...' : 'Upload File'}
                    </button>
                    {error && <p className="error-message">{error}</p>}
                    {!loading && fileUploaded && data.length > 0 && (
                        <div>
                            <p className="file-name">Uploaded File: {file.name}</p>
                            
                            <div className="table-container">
                                <table className="file-management-table">
                                    <thead>
                                        <tr>
                                            {columnNames.map((colName, index) => (
                                                <th key={index}>{colName}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((row, index) => (
                                            <tr key={index}>
                                                {columnNames.map((colName, i) => (
                                                    <td key={i}>{row[colName]}</td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            {/* Data Types Table */}
                            <div className="data-types">
                                <h3>Data Types</h3>
                                <table className="data-types-table">
                                    <thead>
                                        <tr>
                                            <th>Column Name</th>
                                            <th>Data Type</th>
                                            <th>Null Count</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.entries(dataTypes).length > 0 ? (
                                            Object.entries(dataTypes).map(([column, type], index) => (
                                                <tr key={index}>
                                                    <td>{column}</td>
                                                    <td>{type}</td>
                                                    <td>{nullCounts[column] || 0}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="3">No data types available.</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                <p>Total Rows: {rowCount}</p>
                            </div>

                            {/* Upload to Database Button */}
                            <button onClick={handleUploadToDatabase} disabled={loading}>
                                {loading ? 'Uploading to Database...' : 'Upload to Database'}
                            </button>

                            {/* Upload Users Button */}
                            <button onClick={handleUploadUsers} disabled={loading}>
                                {loading ? 'Uploading Users...' : 'Upload Users'}
                            </button>

                            {/* Upload Companies Button */}
                            <button onClick={handleUploadCompanies} disabled={loading}>
                                {loading ? 'Uploading Companies...' : 'Upload Companies'}
                            </button>
                        </div>
                    )}
                    {!loading && fileUploaded && data.length === 0 && <p>No data available.</p>}
                </div>
            </div>
        </div>
    );
};

export default FileManagement;
