import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';

const AssignCompany = ({ onSuccess }) => {
    const [companyList, setCompanyList] = useState([]);
    const [statusList, setStatusList] = useState([]); // New state for statuses
    const [selectedCompany, setSelectedCompany] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        // Fetch the list of companies
        const fetchCompanyList = async () => {
            try {
                const response = await axios.get('/api/company');
                setCompanyList(response.data);
            } catch (error) {
                console.error('Error fetching company list:', error);
            }
        };

        // Fetch the list of statuses from the backend
        const fetchStatusList = async () => {
            try {
                const response = await axios.get('/api/status');
                setStatusList(response.data); // Assuming response contains the list of statuses
            } catch (error) {
                console.error('Error fetching status list:', error);
            }
        };

        fetchCompanyList();
        fetchStatusList(); // Fetch statuses when the component mounts
    }, []);

    const handleCompanyChange = (event) => {
        setSelectedCompany(event.target.value);
    };

    const handleStatusChange = (event) => {
        setSelectedStatus(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            await axios.post('/api/admin/assign-companystatus', {
                companyName: selectedCompany,
                status: selectedStatus
            });

            setMessage(`The company ${selectedCompany} has been assigned the status ${selectedStatus}.`);
            onSuccess();
        } catch (error) {
            console.error('Error assigning company status:', error);
            setMessage('Error assigning company status');
        }
    };

    return (
        <form className="admin-form" onSubmit={handleSubmit}>
            <h2>Assign Company Status</h2>
            <div className="form-group">
                <label htmlFor="companyDropdown">Select Company:</label>
                <select id="companyDropdown" value={selectedCompany} onChange={handleCompanyChange}>
                    <option value="">Select a company</option>
                    {companyList.map((company, index) => (
                        <option key={index} value={company.CompanyName}>
                            {company.CompanyName}
                        </option>
                    ))}
                </select>
            </div>

            <div className="form-group">
                <label htmlFor="statusDropdown">Select Status:</label>
                <select id="statusDropdown" value={selectedStatus} onChange={handleStatusChange}>
                    <option value="">Select a Status</option>
                    {statusList.map((status, index) => (
                        <option key={index} value={status.StatusID}>
                            {status.StatusName}
                        </option>
                    ))}
                </select>
            </div>

            <button type="submit">Assign Status</button>
            {message && <p className="message">{message}</p>}
        </form>
    );
};

export default AssignCompany;
