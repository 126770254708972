// src/components/Admin.js
import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import './Admin.css'; // Import the CSS file
import { AuthContext } from '../context/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const Admin = () => {
    const { auth } = useContext(AuthContext);
    const [userNames, setUsers] = useState([]);
    const [userRoles, setRoles] = useState([]);
    const [selectedUser, setSelectedUser] = useState('');
    const [selectedRole, setSelectedRole] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get('/api/admin/users', {
                    params: { name: auth.name },
                });
                console.log('Fetched Users:', response.data);
                setUsers(response.data);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        const fetchRoles = async () => {
            try {
                const response = await axios.get('/api/admin/roles', {
                    params: { username: auth.name },
                });
                console.log('Fetched Roles:', response.data);
                setRoles(response.data);
            } catch (error) {
                console.error('Error fetching roles:', error);
            }
        };

        fetchUsers();
        fetchRoles();
    }, [auth.name]);

    const handleUserChange = (event) => {
        setSelectedUser(event.target.value);
    };

    const handleRoleChange = (event) => {
        setSelectedRole(event.target.value);
    };

    const handleSubmitRoleChange = async (event) => {
        event.preventDefault();
        try {
            await axios.post('/api/admin/assign-role', {
                username: selectedUser,
                roleName: selectedRole,
            });
            setMessage(`The user ${selectedUser} has been assigned the role ${selectedRole}.`);
        } catch (error) {
            console.error('Error assigning role:', error);
            setMessage('Error assigning role');
        }
    };

    const handlePasswordChange = async (event) => {
        event.preventDefault();

        if (newPassword !== confirmNewPassword) {
            setMessage('Passwords do not match.');
            return;
        }

        try {
            await axios.post('/api/user/adchange-password', {
                username: selectedUser,
                newPassword,
            });
            setMessage(`Password for user ${selectedUser} has been changed successfully.`);
        } catch (error) {
            console.error('Error changing password:', error);
            setMessage('Error changing password');
        }
    };

    return (
        <div className="admin-container">
            <h1>Admin Page</h1>

            {/* Role Assignment Section */}
            <form className="admin-form" onSubmit={handleSubmitRoleChange}>
                <div className="form-group">
                    <label htmlFor="userDropdown">Select User:</label>
                    <select id="userDropdown" value={selectedUser} onChange={handleUserChange}>
                        <option value="">Select a user</option>
                        {userNames.map((userName) => (
                            <option key={userName} value={userName}>
                                {userName}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor="roleDropdown">Select Role:</label>
                    <select id="roleDropdown" value={selectedRole} onChange={handleRoleChange}>
                        <option value="">Select a role</option>
                        {userRoles.map((userRole) => (
                            <option key={userRole.RoleID} value={userRole.RoleName}>
                                {userRole.RoleName}
                            </option>
                        ))}
                    </select>
                </div>

                <button type="submit">Assign Role</button>
            </form>

            {/* Password Change Section */}
            <form className="admin-form" onSubmit={handlePasswordChange}>
                <div className="form-group">
                <label htmlFor="newPassword">
            New Password for <span className="highlight-username">{selectedUser}</span>:
        </label>
                    <input
                        type={showNewPassword ? 'text' : 'password'}
                        id="newPassword"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                    />
                    <FontAwesomeIcon
                        icon={showNewPassword ? faEyeSlash : faEye}
                        className="password-icon"
                        onClick={() => setShowNewPassword(!showNewPassword)}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="confirmNewPassword">Confirm New Password:</label>
                    <input
                        type={showConfirmNewPassword ? 'text' : 'password'}
                        id="confirmNewPassword"
                        value={confirmNewPassword}
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                        required
                    />
                    <FontAwesomeIcon
                        icon={showConfirmNewPassword ? faEyeSlash : faEye}
                        className="password-icon"
                        onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}
                    />
                </div>

                <button type="submit">Change Password</button>
            </form>

            {message && <p className="message">{message}</p>}
        </div>
    );
};

export default Admin;
