


import './ContactUs.css';


const HomePage = () => {
  return (
      <div className="home-page">
          <h1>Welcome Privacy Policy!</h1>
          <p>This is the Privacy Policy page of your application.</p>
          {/* Add more content or styling as needed */}
      </div>
  );
};

export default HomePage;